import React, {useEffect} from "react";
import "./App.css";
import Resume from "../../files/Syed Riadh Hossen.pdf";
import Intro from "./intro";
import About from "./about";
import Education from "./education";
import Skills from "./skills";
import Contact from "./contact";
import Accomplishments from "./accomplishments";
import Work from "./work";

const App = () => {
    useEffect(() => {
        const hash = window.location.hash;

        let about = document.getElementById('about_link');
        let work = document.getElementById('work_link');
        let education = document.getElementById('education_link');
        let skills = document.getElementById('skills_link');
        let contact = document.getElementById('contact_link');

        let hashNames = ["#about", "#work", "#education", "#skills", "#contact"];
        let elements = [about, work, education, skills, contact];

        for (let i = 0; i < hashNames.length; i += 1) {
            if (hash === hashNames[i]) {
                elements[i].className = 'nav-link active';
                elements[i].ariaCurrent = 'page';
            } else {
                elements[i].className = 'nav-link';
            }
        }
    }, []);

    const handleLinkClick = (e) => {
        let about = document.getElementById('about_link');
        let work = document.getElementById('work_link');
        let education = document.getElementById('education_link');
        let skills = document.getElementById('skills_link');
        let contact = document.getElementById('contact_link');

        let hashNames = ["#about", "#work", "#education", "#skills", "#contact"];
        let elements = [about, work, education, skills, contact];

        let hash = e.target.href.split("#")[1];
        hash = "#".concat(hash);

        for (let i = 0; i < hashNames.length; i += 1) {
            if (hash === hashNames[i]) {
                elements[i].className = 'nav-link active';
                elements[i].ariaCurrent = 'page';
            } else {
                elements[i].className = 'nav-link';
            }
        }
    }

    window.addEventListener('scroll', function () {
        let aboutLink = document.querySelector('#about_link');
        let workLink = document.querySelector('#work_link');
        let educationLink = document.querySelector('#education_link');
        let skillsLink = document.querySelector('#skills_link');
        let contactLink = document.querySelector('#contact_link');

        let about = document.querySelector('#about');
        let work = document.querySelector('#work');
        let education = document.querySelector('#education');
        let skills = document.querySelector('#skills');
        let contact = document.querySelector('#contact');

        let aboutPosition = about.getBoundingClientRect();
        let workPosition = work.getBoundingClientRect();
        let educationPosition = education.getBoundingClientRect();
        let skillsPosition = skills.getBoundingClientRect();
        let contactPosition = contact.getBoundingClientRect();

        let divs = [about, work, education, skills, contact];
        let elements = [aboutLink, workLink, educationLink, skillsLink, contactLink];
        let elementPositions = [aboutPosition, workPosition, educationPosition, skillsPosition, contactPosition];

        for (let i = 0; i < elements.length; i += 1) {
            if (Number(elementPositions[i].top) + Number(window.innerHeight / 2) <= Number(window.innerHeight)
                && Number(elementPositions[i].bottom) + Number(window.innerHeight / 2) >= Number(window.innerHeight)) {
                elements[i].className = 'nav-link active';
                elements[i].ariaCurrent = 'page';
                divs[i].style.display = 'block';
            } else {
                elements[i].className = 'nav-link';
                divs[i].style.display = 'hidden';
            }
        }
    });

    const handleResumeDownload = () => {
        let confirm = window.confirm("Download resume?");

        if (confirm) {
            document.getElementById('pseudo_download_button').click();
        }
    }

    return (
        <div id='main_div' className='custom-scrollbar'>
            <nav className='navbar sticky-top navbar-expand-lg navbar-dark bg-dark'>
                <div className='container-fluid'>
                    <a className='navbar-brand' href={'#about'} id='brand'> </a>
                    <button type='button' className='navbar-toggler' data-bs-toggle='collapse'
                            data-bs-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false'
                            aria-label='Toggle navigation'>
                        <span className='navbar-toggler-icon'></span>
                    </button>
                    <div className='collapse navbar-collapse' id='navbarNav'>
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <a className='nav-link' id='about_link' href={"#about"}
                                   onClick={handleLinkClick}>About</a>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-link' id='work_link' href={"#work"}
                                onClick={handleLinkClick}>Work</a>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-link' id='education_link' href={"#education"}
                                   onClick={handleLinkClick}>Education</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id='skills_link' href={"#skills"}
                                   onClick={handleLinkClick}>Skills</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id='contact_link' href={"#contact"}
                                   onClick={handleLinkClick}>Contact</a>
                            </li>
                            <li className="nav-item">
                                <button type='button' className='custom_outline_button'
                                        onClick={handleResumeDownload}>Resume
                                </button>
                                <a id='pseudo_download_button' href={Resume} target='_self' download='Syed Riadh Hossen'
                                   hidden={true}>Resume</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <br/>
            <div id='mid_div'>
                <div id='social_div'>
                    <a href='https://github.com/hossensyedriadh' target='_blank' rel="noreferrer">
                        <i className='fa-brands fa-github'/>
                    </a>
                    <a href='https://linkedin.com/in/syedriadhhossen' target='_blank' rel="noreferrer">
                        <i className='fa-brands fa-linkedin-in'/>
                    </a>
                    <a href='mailto:riadhhossen@gmail.com?subject=Hello' target='_blank' rel="noreferrer">
                        <i className='fa-solid fa-envelope'/>
                    </a>
                </div>
                <div className='container-fluid' id='data_div'>
                    <div id='intro'>
                        <Intro/>
                    </div>
                    <div id='about'>
                        <About/>
                    </div>
                    <div id='work'>
                        <Work/>
                    </div>
                    <div id='education'>
                        <Education/>
                    </div>
                    <div id='skills'>
                        <Skills/>
                    </div>
                    <div id='contact'>
                        <Contact/>
                    </div>
                </div>
            </div>
            <footer>
                <code>
                    Built by <a href='https://github.com/hossensyedriadh/' target='_blank' rel='noreferrer'>
                    Syed Riadh Hossen</a>
                </code>
            </footer>
        </div>
    );
}

export default App;
