import React from "react";
import "./index.css";

const Work = () => {
    return (
        <div id='work_div'>
            <p className='fs-4'><i className='fa-solid fa-briefcase'/> Work</p>
            <hr/>
            <div id='work_items'>
                <ul>
		    <li>
                        <div className='work_item'>
                            <p className='fs-4'>Software Engineer (Consulting)</p>
                            <p className='fs-5'>Self Employed</p>
                            <p>Sept 2024 - Present</p>
                        </div>
                    </li>
                    <br/>
                    <li>
                        <div className='work_item'>
                            <span className='fs-4'>Application Developer</span>
			    <br/>
			    <small>Outsourced from Frontdesk Bangladesh Limited</small>
			    <br/><br/>
                            <p className='fs-5'><a href="https://banglalink.net/en" target="_blank" rel='noreferrer'>Banglalink Digital Communications Limited</a></p>
                            <p className='fs-7'>Dhaka, Bangladesh</p>
                            <p>2<sup>nd</sup> Jul 2023 - 30<sup>th</sup> Nov 2024</p>
                        </div>
                    </li>
                    <br/>
                    <li>
                        <div className='work_item'>
                            <p className='fs-4'>Junior Software Engineer</p>
                            <p className='fs-5'>Tech Cloud Ltd.</p>
                            <p className='fs-7'>Dhaka, Bangladesh</p>
                            <p>9<sup>th</sup> Jan 2023 - 2<sup>nd</sup> Jul 2023</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Work;
