import React from "react";
import "./index.css";

const About = () => {
    return (
        <div id='about_me'>
            <p className='fs-4'><i className='fa-solid fa-user'/> About me</p>
            <hr/>
            <p id='about_me_paragraph'>
                Hello! I am Syed Riadh Hossen, a Software Engineer with proven ability to work and collaborate
                efficiently in an agile, fast-moving environment, contributing to the delivery of scalable, efficient
                software solutions through object-oriented approaches. I am a detail-oriented person with passion for
                tackling complex problems and commitment to delivering high-quality code, regularly refreshing my skills
                to ensure to keep up with ongoing developments.
                <br/>
                <br/>
                I excel in both work and personal pursuits with a calm focus on detail and a quick grasp of new skills
                with ability to thrive in dynamic environment, adapting and innovating to contribute to success.
                <br/>
            </p>
        </div>
    );
}

export default About;
