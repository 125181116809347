import React, {useEffect} from "react";
import AppV1 from "./v1/App";
import AppV2 from "./v2/App";

const Decider = () => {
    const [isConsole, setIsConsole] = React.useState(true);

    useEffect(() => {
        document.getElementById("consoleButton").click();
    }, []);

    return (
        <div>
            <button type="button" id="consoleButton" className="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#decisionModal" hidden>
                Use Console
            </button>

            <div className="modal fade" id="decisionModal" data-bs-backdrop="static"
                 tabIndex="-1"
                 aria-labelledby="decisionModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            Want to continue with the Console version? Or switch to Standard version?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-bs-dismiss="modal"
                                    onClick={() => setIsConsole(true)}>Console
                            </button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                    onClick={() => setIsConsole(false)}>Standard
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isConsole ? <AppV2/> : <AppV1/>}
        </div>
    );
};

export default Decider;